"use strict";

require("./..\\..\\..\\bower_components\\jquery.serializeJSON\\jquery.serializejson.js");

module.exports = function () {
	$('[smart-filter]').each(function () {
		var $bar = $(this),
			$form = $bar.closest('form'),
			$buttons = $form.find('[type="submit"]'),
			formUrl = $form.attr('action'),
			$barValue = $bar.find('[smart-filter-value]'),
			emptyValues = {},
			changeDelay, hideBarDelay;

		getFilterResults()
			.then(formUpdate);

		//fill emptyValues
		$form.find('[range-input]').each(function (index, elem) {
			var $wrap = $(elem),
				fromName = $wrap.find('[price-range-from]').attr('name'),
				toName = $wrap.find('[price-range-to]').attr('name'),
				min = parseFloat($wrap.attr('min')),
				max = parseFloat($wrap.attr('max'));

			if(fromName) {
				emptyValues[fromName] = min;
			}
			if(toName) {
				emptyValues[toName] = max;
			}
		});

		console.log(emptyValues);

		$form.on('change rangechange', function (event) {
			var $input = $(event.target);

			clearTimeout(changeDelay);
			changeDelay = setTimeout(function () {
				showBar($input);

				getFilterResults()
					.then(formUpdate)
					.then(autoHideBar);
			}, 200);
		});

		$form.on('submit', function (event) {
			event.preventDefault();
			document.location.href = formUrl;
		});

		function formUpdate(response) {
			barUpdate(response);
			disableInputs(response);
			formUrl = response.JS_FILTER_PARAMS.SEF_SET_FILTER_URL || formUrl;
		}

		function showBar($input) {
			var barOffset = getBarOffset($input, $form);

			$buttons.attr('disabled', 'disabled');
			$bar
				.addClass('load')
				.css('top', barOffset)
				.show();
		}

		function autoHideBar() {
			clearTimeout(hideBarDelay);
			hideBarDelay = setTimeout(function () {
				$bar.hide();
			}, 10000);
		}

		function getFilterResults() {
			var data = $.extend({ajax: 'y'}, $form.serializeJSON());

			$.each(data, function (index, value) {
				if(parseFloat(value) == emptyValues[index]) {
					console.log(value);
					delete data[index];
				}
			});

			return $.getJSON($form.attr('action'), data);
		}

		function barUpdate(responseObj) {
			$buttons.removeAttr('disabled');
			$bar.removeClass('load');
			$barValue.html(responseObj.ELEMENT_COUNT || 0);
		}

		function disableInputs(responseObj) {
			if(!responseObj.ITEMS) return;

			$.each(responseObj.ITEMS, function (index, item) {
				$.each(item.VALUES, function (index, value) {
					var $input = $form.find('[name="'+ value.CONTROL_NAME +'"]'),
						$label = $input.closest('label'),
						isICheck = $input.closest('.icheckbox');

					if(value.DISABLED) {
						$input.attr('disabled', 'disabled');
						$label.addClass('disabled');
						if(isICheck) $input.iCheck('disable');
					} else {
						$input.removeAttr('disabled');
						$label.removeClass('disabled');
						if(isICheck) $input.iCheck('enable');
					}
				});
			});
		}




	});
};

function getBarOffset($input, $form) {
	var target = $input.closest('label');

	if(!target.length) target = $input;

	return target.offset().top + target.outerHeight() / 2 - $form.offset().top;
}