(function (factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(['jquery'], factory);
	} else if (typeof exports === 'object') {
		// Node/CommonJS
		module.exports = factory(require("./..\\..\\..\\bower_components\\jquery\\dist\\jquery.js"));
	} else {
		// Browser globals
		factory(jQuery);
	}
}(function ($) {
	"use strict";

	var pluginName = 'collapse',
		defaults = {
			openDuration  : 300,
			closeDuration : 300,
			preventDefault : true,
			animation : true
		},
		$window = $(window);

	function Plugin ( element, options ) {
		this.element = element;
		this.settings = $.extend( {}, defaults, options );
		this._defaults = defaults;
		this._name = pluginName;
		this.init();
	}

	$.extend(Plugin.prototype, {
		init : function () {
			this.element.trigger(pluginName+'.beforeInit');

			var _this = this,
				$element = this.element,
				$button = $element.find('[collapse-button]'),
				$wrapper = $element.find('[collapse-content]'),
				$group = $element.closest('[collapse-group]'),
				$siblings = $group.length && $group.find('[collapse]').not($element),
				$closestElems = $siblings ? $element.add($siblings) : $element;

			this.wrapper = $wrapper;
			this.siblings = $siblings;

			if($wrapper.css('position') == 'absolute') {
				$window.on('click.collapse', function (event) {
					if(!_this.isOpen) return;

					var $target = $(event.target);

					if(!$target.closest($closestElems).length) {
						_this.close();
					}
				});
			}


			if(!$element.hasClass('open')) {
				$wrapper.hide();
				this.isOpen = false;
			} else {
				$wrapper.show();
				this.isOpen = true;
			}

			$button.on('click', function (event) {
				if(_this.settings.preventDefault) event.preventDefault();

				if(_this.isOpen) {
					_this.close();
				} else {
					_this.open();
				}
			});

			this.element.trigger(pluginName+'.afterInit');
		},


		open : function () {
			if(this.isOpen) return;

			this.element.trigger(pluginName+'.beforeOpen');

			if(this.settings.animation) {
				this.wrapper
					.stop(true, true)
					.fadeIn({
						duration: this.settings.openDuration,
						queue: false
					})
					.css('display', 'none')
					.slideDown(this.settings.openDuration);
			} else {
				this.wrapper.show();
			}


			this.element.addClass('open');
			this.isOpen = true;

			if(this.siblings) {
				this.siblings[pluginName]('close');
			}

			this.element.trigger(pluginName+'.afterOpen');
		},


		close : function () {
			if(!this.isOpen) return;
			this.element.trigger(pluginName+'.beforeClose');

			if(this.settings.animation) {
				this.wrapper
					.stop(true, true)
					.fadeOut({
						duration: this.settings.closeDuration,
						queue: false
					})
					.slideUp(this.settings.closeDuration);
			} else {
				this.wrapper.hide();
			}

			this.element.removeClass('open');
			this.isOpen = false;

			this.element.trigger(pluginName+'.afterClose');
		}

	});

	$[ pluginName ] = Plugin;


	$.fn[ pluginName ] = function ( options ) {
		this.each(function() {
			if ( !$.data( this, 'plugin_' + pluginName ) ) {
				$.data( this, 'plugin_' + pluginName, new Plugin( $(this), options ) );
			} else {
				if(typeof options == 'string') {
					$(this).data('plugin_' + pluginName)[options]();
				}
			}
		});

		return this;
	};
}));