"use strict";

require("./..\\..\\..\\bower_components\\magnific-popup\\dist\\jquery.magnific-popup.js");

var moduleName = 'mfp-links';

module.exports = function () {
	$('[image-popup-link]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $link = $(this);

		if( $link.closest('[image-popup-gallery]').length ) return;

		$link.magnificPopup({
			type: 'image',
			image: {
				tError: '<a href="%url%">Изображение</a> недоступно.'
			},
			callbacks: {
				beforeOpen: function() {
					this.st.mainClass = 'mfp-zoom-in';
				}
			},
			removalDelay: 300
		});
	});

	$('[image-popup-gallery]').magnificPopup({
		delegate: '[image-popup-link]',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1],
			tCounter: '%curr% из %total%'
		},
		type: 'image',

		image: {
			tError: '<a href="%url%">Изображение</a> недоступно.'
		},
		callbacks: {
			beforeOpen: function() {
				this.st.mainClass = 'mfp-zoom-in';
			}
		},
		removalDelay: 300
	});

	$('[inline-popup-link]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $link = $(this),
			$wrap = $link.closest('[inline-popup-link-wrap]'),
			$popup =  $wrap.length ? $wrap.find('[inline-popup]') : $($link.attr('href')),
			popupTitle = $link.attr('data-popup-title') || '',
			itemId = $link.attr('data-item-id') || '',
			offerId = $link.attr('data-offer-id') || '',
			$ajaxForm = $popup.find('[ajax-form]');

		$link.magnificPopup({
			items: {
				src: $popup,
				type: 'inline'
			},
			callbacks: {
				beforeOpen: function() {
					this.st.mainClass = 'mfp-zoom-in';
					$popup.find('[popup-title]').text(popupTitle);
					$popup.find('[item-id]').val(itemId);
					$popup.find('[offer-id]').val(offerId);
				},
				afterClose: function () {
					if($ajaxForm.length) {
						$ajaxForm.data('ajax-form').hideSuccess();
					}
				}
			},
			removalDelay: 300
		});
	});

	$('[video-popup-item]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $item = $(this),
			$links = $item.find('a'),
			title = $item.find('[video-popup-title]').text(),
			caption = $item.find('[video-popup-caption]').html();

		$links.magnificPopup({
			type: 'iframe',
			iframe: {
				markup: '<div class="video-popup">'+
				            '<div class="mfp-close"></div>'+
							'<h2 class="video-popup-title">'+title+'</h2>'+
				            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
							'<div class="video-popup-caption">'+caption+'</div>'+
						'</div>'
			}
		});
	});

	$('[mfp-opened]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $popup = $(this);

		$.magnificPopup.open({
			items: {
				src: $popup,
				type: 'inline'
			}
		});
	});
};