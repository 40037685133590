"use strict";

require("./..\\..\\..\\bower_components\\parsleyjs\\dist\\parsley.js");
require('../../../bower_components/parsleyjs/src/i18n/ru');

var lang = $('html').attr('lang'),
	moduleName = 'parsley';

if(lang) window.ParsleyValidator.setLocale(lang);

module.exports = function () {
	$('[parsley]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $form = $(this);

		$form.parsley().subscribe('parsley:form:validated', function (event) {  //focus error field offset
			var fieldOffset = event._focusedField.closest(':visible').offset().top;

			if($(window).scrollTop() + 75 > fieldOffset) {

				$('html, body').scrollTop(fieldOffset - 100);
			}
		});
	});
};