"use strict";

require('../../../bower_components/jquery-ui/ui/core');
require('../../../bower_components/jquery-ui/ui/widget');
require('../../../bower_components/jquery-ui/ui/button');
require('../../../bower_components/jquery-ui/ui/spinner');

$.widget( "ui.spinner", $.ui.spinner, {
	_buttonHtml: function() {
		return "" +
			'<button class="ui-spinner-button ui-spinner-up" type="button"></button>' +
			'<button class="ui-spinner-button ui-spinner-down" type="button"></button>';
	}
});

var moduleName = 'spinner';

function init() {
	$('[spinner]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $input = $(this);

		if($input.val() === '') {
			$input.val(1);
		}

		$input.spinner({
			min : 1
		});
	});
}

module.exports = init;