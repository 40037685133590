"use strict";
var $ = window.jQuery = window.$ = require("./..\\..\\bower_components\\jquery\\dist\\jquery.js");

window.icheckInit = require('./modules/icheckInit');

var initCustomWidgets = window.initCustomWidgets = function () {
	require('./modules/parsleyInit')();
	window.icheckInit();
	require('./modules/mfpInit')();
	require('./modules/mpSliderInit')();
	require('./modules/spinnerInit')();
	require('./modules/catalogMenuInit')();
	require('./modules/rangeInputInit')();
	require('./modules/tooltipInit')();
	require('./modules/collapseInit')();
	require('./modules/cardSliderInit')();
	require('./modules/gridSliderInit')();
	require('./modules/scrollToLinkInit')();
	require('./modules/sortSelectInit')();
	require('./modules/maskedInputInit')();
	require('./modules/addCartInit')();
	require('./modules/bitrixAjaxFormInit')();
	require('./modules/smartFilterInit')();
};

$(initCustomWidgets);





