"use strict";

require("./..\\..\\..\\bower_components\\slick-carousel\\slick\\slick.min.js");


var moduleName = 'grid-slider';

module.exports = function () {
	$('[grid-slider]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $slider = $(this),
			slideQuery = $slider.find('[grid-slider-item]').length ? '[grid-slider-item]' : '';

		$slider.slick({
			dots : false,
			prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous"></button>',
			nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next"></button>',
			slide: slideQuery,
			appendArrows: $slider,
			infinite: false,
			draggable: false,
			slidesToShow: 4,
			slidesToScroll: 1
		});
	});
};