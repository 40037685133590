"use strict";

require("./..\\..\\..\\bower_components\\parsleyjs\\dist\\parsley.js");

module.exports = BitrixAjaxForm;

function BitrixAjaxForm($wrap) {
	this.$wrap = $wrap;
}

BitrixAjaxForm.prototype.init = function () {
	var _this = this;

	this.$form = this.$wrap.find('form');
	if(!this.$form.length) this.$form = this.$wrap;

	this.formAction = this.$form.attr('action');

	this.$form.parsley();

	this.$form.on('submit.BitrixAjaxForm', $.proxy(this.submitHandler, this));



	if(window.initCustomWidgets) window.initCustomWidgets();
};

BitrixAjaxForm.prototype.destroy = function () {
	if(this.$form.parsley().destroy)  this.$form.parsley().destroy();
	this.$form.off('.BitrixAjaxForm');
};

BitrixAjaxForm.prototype.sendForm = function () {
	return $.ajax({
		url : this.formAction,
		type : this.$form.attr('method') || 'POST',
		data : this.$form.serialize(),
		dataType : 'html'
	});
};

BitrixAjaxForm.prototype.processResponse = function (html) {
	this.destroy();
	this.$wrap.html(html);
	this.init();

	this.$wrap.find('[data-remote-error]').each(function (index, input) {
		var $input = $(input),
			parsleyInstance = $input.parsley();

		window.ParsleyUI.addError( parsleyInstance, 'remote', $input.attr('data-remote-error') || '' );

		$input.one('input', function () {
			window.ParsleyUI.removeError(parsleyInstance, 'remote');
		});
	});
};

BitrixAjaxForm.prototype.submitHandler = function (event) {
	event.preventDefault();

	if(this.$form.parsley().isValid() === true) {
		this.sendForm().then($.proxy(this.processResponse, this));
	}
};

