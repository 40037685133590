"use strict";

require("./..\\..\\..\\bower_components\\jquery.cookie\\jquery.cookie.js");

var deparam = require("./..\\..\\..\\bower_components\\jquery-deparam\\jquery-deparam.js");

module.exports = function () {
	$('[sort-select]').each(function () {
		var $select = $(this);

		$select.on('change', function () {
			var $selectedOption = $(this).find('option:selected'),
				urlParamsString = location.search.substr(1);

			$.cookie(
				'ahead_catalog-sort',
				$selectedOption.data('sortString'),
				{
					path: '/',
					expires: 365,
					domain: location.hostname.replace(/^.*(\.[^\.]+\.[^\.]+)$/, '$1')
				}
			);

			window.location.search = getSearchWithoutSortParams(window.location.search);
		})
	});
};

function getSearchWithoutSortParams (search) {
	var obj = deparam(search.substr(1));

	$.each(obj, function (i) {
		if(/PAGEN_|sort-order|sort-by/i.test(i)) {
			delete obj[i];
		}
	});

	return '?' + $.param(obj);
}