"use strict";

require("./..\\..\\..\\bower_components\\scrollport-js\\dist\\scrollport.min.js");

var moduleName = 'scroll-to-link';

module.exports = function () {
	$('[scroll-to-link]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $link = $(this),
			$target = $($link.attr('href'));

		$link.on('click', function (event) {
			event.preventDefault();
			$.scrollport($target, {
				delta : 100,
				mode : 'roll'
			});
		});
	});


};