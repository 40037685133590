"use strict";

var moduleName = 'tooltip';

require("./..\\..\\..\\bower_components\\tooltipster\\js\\jquery.tooltipster.min.js");


module.exports = function () {
	$('[tooltip]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $tooltip = $(this),
			$content = $tooltip.find('[tooltip-content]');

		$tooltip
			.tooltipster({
				content : $content,
				trigger : 'click',
				position : 'right',
				animation : 'slide',
				delay : 0,
				contentCloning : false,
				interactive : true
			});
	});
};