"use strict";

require("./..\\..\\..\\bower_components\\slick-carousel\\slick\\slick.min.js");

var moduleName = 'mp-slider';

module.exports = function () {
	$('[mp-slider]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $wrap = $(this),
			$addNav = $wrap.find('[mp-slider-nav]'),
			$addNavItems = $addNav.find('li'),
			$slider = $wrap.find('[slick-slider]'),
			slideQuery = $slider.find('[slick-slider-item]').length ? '[slick-slider-item]' : '',
			$nav = $slider.find('[slick-slider-nav]');

		if(!$nav.length) {
			$nav = $('<div class="slick-nav" slick-slider-nav>');
			$slider.one('init', function () {
				$slider.append($nav);
			});
		}

		$slider.on('init', function (event, slick) {
			$addNavItems.removeClass('active');
			$addNavItems.eq(slick.currentSlide).addClass('active');
		});

		$slider.slick({
			dots : true,
			prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous"></button>',
			nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next"></button>',
			customPaging: function(slider, i) {
				return '<button type="button" data-role="none"></button>';
			},
			slide: slideQuery,
			appendDots: $nav,
			appendArrows: $nav,
			autoplay: true,
			autoplaySpeed: 5000
		});


		$slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			$addNavItems.removeClass('active');
			$addNavItems.eq(nextSlide).addClass('active');
		});

		$addNavItems.each(function (index, elem) {
			$(elem).on('click', function () {
				$slider.slick('slickGoTo', index);
			});
		});
	});
};