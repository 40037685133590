"use strict";

require("./..\\..\\..\\bower_components\\iCheck\\icheck.min.js");

var moduleName = 'icheck';

module.exports = function () {
	$('[icheck]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $input = $(this);

		$input.iCheck().on('ifChanged', function () {
			$(this).trigger('change');
		});
	});
};