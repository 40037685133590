"use strict";

require('./collapsePlugin');

var moduleName = 'collapse';

module.exports = function () {
	$('[collapse]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $block = $(this);

		$block.collapse();
	});
};