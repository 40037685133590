"use strict";

var moduleName = 'add-cart';

module.exports = function () {
	$('[add-cart]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $wrap = $(this),
			$button = $wrap.find('[add-cart-button]'),
            url = $button.attr('href');

		$button.on('click', function (event) {
            event.preventDefault();

            $.get(url)
	            .then(function() {
	                updateCart();
		            $wrap.addClass('added');
	            });
        });
	});
};

function updateCart() {
	var href = '/ajax/updateCart.php',
		data = {
			'data' : $('#cart-small-to-ajax-update').val()
		};

	$.post(href, data, function(output) {
		$('#header-cart').html(output);
	});
}