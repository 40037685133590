"use strict";


var moduleName = 'bitrix-ajax-form',
	BitrixAjaxForm = require('./BitrixAjaxForm');


module.exports = function () {
	$('[bitrix-ajax-form]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $wrap = $(this),
			bitrixAjaxForm = new BitrixAjaxForm($wrap);

		bitrixAjaxForm.init();

	});
};