"use strict";

require("./..\\..\\..\\bower_components\\tap\\dist\\tap.js");

module.exports = function () {
	$('[catalog-menu]').each(function () {
		var $menu = $(this),
			$menuButton = $menu.children('a'),
			$menuList = $menu.children('ul'),
			$subMenus = $menuList.children('li'),
			$window = $(window),
			subMenuTimer;

		$menu.on('mouseenter', function () {
			$menu.addClass('open');
		});
		$menu.on('mouseleave', function () {
			$menu.removeClass('open');
		});

		$menuButton.on('click tap', function (event) {
			$menu.addClass('open');
		});

		$window.on('tap', function (event) {
			if(!$(event.target).closest($menu).length) {
				$menu.add($subMenus).removeClass('open');
			}
		});

		$subMenus.each(function () {
			var $menu = $(this),
				$menuButton = $menu.children('a'),
				$menuList = $menu.children('.catalog-menu-item-inner');

			if(!$menuList.length) return;

			$menu.addClass('openable');

			$menu.on('mouseenter', function () {
				clearTimeout(subMenuTimer);
				subMenuTimer = setTimeout(function () {
					if(!$menu.hasClass('open')) {
						$subMenus.removeClass('open');
						$menu.addClass('open');
					}
				},100);
			});
			$menu.on('mouseleave', function () {
				clearTimeout(subMenuTimer);
				subMenuTimer = setTimeout(function () {
					$subMenus.removeClass('open');
				}, 100)
			});
			$menuButton.on('tap', function (event) {
				$subMenus.removeClass('open');
				$menu.addClass('open');
			});
		});
	});
};