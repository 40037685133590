"use strict";

require('../../../bower_components/jquery-ui/ui/core');
require('../../../bower_components/jquery-ui/ui/widget');
require('../../../bower_components/jquery-ui/ui/mouse');
require('../../../bower_components/jquery-ui/ui/slider');

var moduleName = 'range-input';

function init() {
	$('[range-input]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var $wrap = $(this),
			$slider = $wrap.find('[price-range-slider]'),
			$fromInput = $wrap.find('[price-range-from]'),
			$toInput = $wrap.find('[price-range-to]'),
			min = parseFloat($wrap.attr('min')) || 0,
			max = parseFloat($wrap.attr('max')) || 10000,
			step = parseFloat($wrap.attr('step')) || 1;

		$slider.slider({
			range: true,
			min : min,
			max : max,
			step : step,
			values : getInputValues(),
			slide: function( event, ui ) {
				$fromInput.val(ui.values[0]);
				$toInput.val(ui.values[1]);
			}
		});

		$fromInput.add($toInput).on('input change propertychange', function () {
			$slider.slider('values', getInputValues());
		});

		$slider.on('slidechange', function () {
			$fromInput.trigger('rangechange');
			$toInput.trigger('rangechange');
		});

		function getInputValues() {
			return [
				parseFloat($fromInput.val()) || 0,
				parseFloat($toInput.val()) || 0
			];
		}
	});
}

module.exports = init;