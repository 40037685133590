"use strict";

require("./..\\..\\..\\bower_components\\tap\\dist\\tap.js");
require("./..\\..\\..\\bower_components\\slick-carousel\\slick\\slick.min.js");
require("./..\\..\\..\\bower_components\\magnific-popup\\dist\\jquery.magnific-popup.js");

var moduleName = 'card-slider';

module.exports = function () {
	$('[card-slider]').each(function () {
		if($.data(this, moduleName + '-init')) return;
		$.data(this, moduleName + '-init', true);

		var slider = new CardSlider($(this));

		slider.initGallery();
		if(slider.$navSlides.length > 5) {
			slider.initSlider();
		}

	});
};

function CardSlider($wrap) {
	this.$navLinks = $wrap.find('[card-slider-img-link]');
	this.$bigImg = $wrap.find('[card-slider-main-img]');
	this.$zoomLink = $wrap.find('[card-slider-zoom-link]');
	this.$navSlider = $wrap.find('[card-slider-nav]');
	this.$navSlides = $wrap.find('[card-slider-item]');

	this.activeItemIndex = 0;
	this.galleryItems = [];
}

CardSlider.prototype.initGallery = function () {
	var _this = this;

	this.$navLinks.each(function (index, item) {
		var $item = $(item),
			bigImgUrl = $item.attr('href'),
			midImgUrl = $item.attr('card-slider-img-link');

		_this.galleryItems.push({
			src : bigImgUrl
		});

		$item.on('click tap', function (event) {
			event.preventDefault();
			_this.$navLinks.eq(_this.activeItemIndex).closest('.product-small-photo').removeClass('active');
			_this.activeItemIndex = index;
			$item.closest('.product-small-photo').addClass('active');
			_this.setMainImage(midImgUrl, bigImgUrl);
		});
	});

	this.$zoomLink.on('click tap', function (event) {
		event.preventDefault();
		_this.showPopup();
	});
};

CardSlider.prototype.setMainImage = function (midImgUrl, bigImgUrl) {
	this.$bigImg.attr('src', midImgUrl);
	this.$zoomLink.attr('href', bigImgUrl);
};

CardSlider.prototype.showPopup = function () {
	$.magnificPopup.open({
		items : this.galleryItems,
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1],
			tCounter: '%curr% из %total%'
		},
		type: 'image',

		image: {
			tError: '<a href="%url%">Изображение</a> недоступно.'
		},
		callbacks: {
			beforeOpen: function() {
				this.st.mainClass = 'mfp-zoom-in';
			}
		},
		removalDelay: 300
	}, this.activeItemIndex);
};

CardSlider.prototype.initSlider = function () {
	this.$navSlider.slick({
		dots : false,
		prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="previous"></button>',
		nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="next"></button>',
		slide: '[card-slider-item]',
		appendArrows: this.$navSlider,
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		vertical: true,
		verticalSwiping: true,
		speed : 200
	});
};

